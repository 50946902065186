// Main.js

import React from 'react';

function MainPage() {
    return (
        <div>
          <h2>Main Page</h2>
            <div>
                <button onClick={() => alert("open api")}>Call Open API</button>
                <button onClick={() => alert("protected api")}>Call Protected API</button>
            </div>
        </div>
      );
}

export default MainPage;
