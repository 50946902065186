import logo from './logo.svg';
import './App.css';

import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';

// Import pages
import MainPage from './MainPage';
import SubscriptionPage from './SubscriptionPage';
import CheckoutPage from './CheckoutPage';
import TermsAndConditionsPage from './TermsAndConditionsPage';

import { Amplify } from 'aws-amplify';
import { get } from 'aws-amplify/api';
import { fetchAuthSession, signOut, getCurrentUser } from 'aws-amplify/auth'

import awsExports from './aws-exports';

import { Authenticator, View, useAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

Amplify.configure(awsExports);

function AuthenticatedContent(signOut, clientId) {
    return (
        <Router>
        <div>
            <div>
            <nav>
                <ul>
                <li>
                    <Link to="/.">Main</Link> {}
                </li>
                <li>
                    <Link to="/subscribe">Subscribe</Link> {}
                </li>
                <li>
                    <Link to="/terms">Terms and Conditions</Link> {}
                </li>
                <li>
                    <div onClick={signOut}>
                        <Link to="/.">Sign Out</Link>
                    </div>
                </li>
                </ul>
            </nav>
            </div>
            
            {/* Your navigation, etc. */}
            <Routes>
            <Route path="/subscribe" element={<SubscriptionPage clientId={clientId} />} />
            <Route path="/checkout" element={<CheckoutPage />} />
            <Route path="/terms" element={<TermsAndConditionsPage />} />
            {/* Other routes... */}
            <Route path="*" element={<MainPage />} /> {/* Default route */}
            </Routes>
        </div>
        </Router>
    );
}

function configureAmplifyWithAccessToken(authToken) {
    if (!authToken) {
        Amplify.configure(awsExports);
        return;
    }
    Amplify.configure(awsExports, {
        API: {
            REST: {
                headers: async () => {
                    return { Authorization: authToken };
                }
            }
        }
    });
}

async function getItems() {
    try {
        const restOperation = get({ 
            apiName: 'yeterApi',
            path: '/yeter'
        });
        return await restOperation.response;
    } catch (error) {
        return 'GET call failed: ', error;
    }
}


export default function App() {

    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const [debugInfo, setDebugInfo] = useState({});

    const APIComponent = () => {
        const [apiResponse, setApiResponse] = useState('Items loading');
        
        useEffect(() => {
            const fetchData = async () => {
                try {
                    const response = await getItems();
                    setApiResponse(response);
                } catch (error) {
                    setApiResponse('Error fetching data:', error);
                }
            };
            // if (isAuthenticated) {
            //     fetchData();
            // } else {
            //     setApiResponse('Not authenticated');
            // }
        }, []);
        
        return (
            <div>
            <input type="text" value={apiResponse} readOnly style={{ width: '900px' }} />
            </div>
        );
    };

    const Home = () => {
        const { user, signOut } = useAuthenticator((context) => [context.user]);
        const { route } = useAuthenticator(context => [context.route]);
        const [clientId, setClientId] = useState('');

        useEffect(() => {
            setIsAuthenticated(route === 'authenticated');
        }, [route]);

        useEffect(() => {
            const fetchAccessToken = async () => {
                try {
                    const authSession = await fetchAuthSession();
                    const token = authSession.tokens?.idToken?.toString();
                    setClientId(authSession.userSub);

                    configureAmplifyWithAccessToken(token);
                } catch (error) {
                    configureAmplifyWithAccessToken(null);
                }
            };
            if (isAuthenticated) {
                fetchAccessToken();
            } else {
                configureAmplifyWithAccessToken(null);
            }
        }, [isAuthenticated]);

        return (
            <View> 
                {isAuthenticated ? (AuthenticatedContent(signOut, clientId)) : (<Authenticator />)}
                {<APIComponent />}
            </View>
        )
      };

    return (
      <Authenticator.Provider>
        <View><Home /></View>
      </Authenticator.Provider>
    );
};

// async function fetchData() {
//     const jwtToken = await getAccessToken();
//     if (jwtToken) {
//         try {
//             const response = await fetch('https://your-api-endpoint/message', {
//                 headers: {
//                 Authorization: jwtToken
//                 }
//             });
//             const data = await response.json();
//             console.log(data); // Your API response
//         } catch (error) {
//             console.error("Error fetching data", error);
//         }
//     }
// }

// function App() {
//     const [accessToken, setAccessToken] = useState(null);

//     async function fetchAccessToken() {
//         try {
//             const token = (await fetchAuthSession()).tokens?.accessToken.toString();
//             console.log("Access token fetched: ", token)
//             return token;
//         } catch (error) {
//             console.error("Error getting Access token", error);
//         }
//     }

//     const handleSignOut = (signOutFunction) => {
//         // Clear the access token
//         setAccessToken(null);

//         // Call the original sign out function
//         signOutFunction();
//     };

//     useEffect(() => {
//         const fetchToken = async () => {
//             try {
//                 // Replace this with your actual token fetching logic
//                 const token = await fetchAccessToken();
//                 setAccessToken(token);
//             } catch (error) {
//                 console.error('Error fetching token:', error);
//             }
//         };

//         fetchToken();
//     }, []); // Empty dependency array means this runs once after the component mounts

//     return (
//         <Authenticator>
//             {({ signOut, user }) => (
//                 <div>
//                     <p>Welcome {user.username}</p>
//                     {accessToken ? (
//                         <div>
//                             <p>Token: {accessToken}</p>
//                         </div>
//                     ) : (
//                         <p>Loading access token...</p>
//                     )}
//                     <button onClick={() => handleSignOut(signOut)}>Sign out</button>
//                 </div>
//             )}
//         </Authenticator>
//     );
// }

// export default App;



// export default App;