import React, { useEffect, useState } from 'react';

import {
    BrowserRouter as Router,
    Route,
    Routes,
    Navigate
  } from "react-router-dom";

// todo serhat - bunu lambda fonksiyonu ile yap
const stripe = require('stripe')('sk_test_51OeTyyI4caQRTBBwSmeAXhWMiVU6fpf7pgPu8PAFBKzedhnGAeQC6j6WrM6xczU78ixsb1tqcTsyfNBG3CMrRoIK00cjJuFae8');

function CheckoutPage() {

    // const session = await stripe.checkout.sessions.retrieve(req.query.session_id);

    const [status, setStatus] = useState(null);
    const [customerEmail, setCustomerEmail] = useState('');

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const sessionId = urlParams.get('session_id');

        const fetchData = async () => {
            try {
                const session = await stripe.checkout.sessions.retrieve(sessionId);
                setStatus(session.status);
                setCustomerEmail(session.customer_details.email);
            } catch (error) {
                console.log(error)
            }
        };
        fetchData();
    }, []);

    if (status === 'open') {
        return (
        <Navigate to="/subscription" />
        )
    }

    if (status === 'complete') {
        return (
        <section id="success">
            <p>
            We appreciate your business! A confirmation email will be sent to {customerEmail}.

            If you have any questions, please email <a href="mailto:orders@example.com">orders@example.com</a>.
            </p>
        </section>
        )
    }

    return null;
}

export default CheckoutPage;