// SubscriptionPage.js

import React, { useEffect, useState } from 'react';

import { StripeProvider } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';

// public 
// const stripePromise = loadStripe('pk_live_51OeTyyI4caQRTBBw9pmNlmuIHt6PBBpHYgwPUUAbQQKmWN2hFNkLtsBwrHR7T09vXbInUxfW4IivcXdy00fRACuk00xcqzKzxj');

// test
const stripePromise = loadStripe('pk_live_51OeTyyI4caQRTBBw9pmNlmuIHt6PBBpHYgwPUUAbQQKmWN2hFNkLtsBwrHR7T09vXbInUxfW4IivcXdy00fRACuk00xcqzKzxj');

const CheckoutForm = () => {
    const [clientSecret, setClientSecret] = useState('');

    useEffect(() => {
        // Create a Checkout Session as soon as the page loads
        fetch("/create-checkout-session", {
        method: "POST",
        })
        .then((res) => res.json())
        .then((data) => setClientSecret(data.clientSecret));
    }, []);

    return (
        <div id="checkout">
        {clientSecret && (
            <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={{clientSecret}}
            >
            <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
        )}
        </div>
    )
}

const StripePricingTable = ({ pricingTableId, publishableKey, clientId }) => {
    useEffect(() => {
      // Load Stripe pricing table script
      const script = document.createElement('script');
      script.src = 'https://js.stripe.com/v3/pricing-table.js';
      script.async = true;
      document.body.appendChild(script);
  
      return () => {
        // Remove script on component unmount
        document.body.removeChild(script);
      };
    }, []);
  
    return (
      <stripe-pricing-table
      client-reference-id={clientId}
      pricing-table-id={pricingTableId}
      publishable-key={publishableKey}
      ></stripe-pricing-table>
    );
  };

function SubscriptionPage({ clientId }) {
    return (
        // <StripeProvider stripe={stripePromise}>
        // {/* Your app component */}
        // </StripeProvider>
        <StripePricingTable
        pricingTableId="prctbl_1OemtzI4caQRTBBwhaHDweyR"
        publishableKey="pk_test_51OeTyyI4caQRTBBwvT4aunJtsp526BhSWkCg5edYDAnFKo64X6FCrDFEZbZLkM3NRtC3qGIMImB60E7Dn8Bla26A00zIcDT6CQ"
        clientId={clientId}
      />
    );
}

// import React from 'react';

// function SubscriptionPage() {
//     return (
//         <div>
//         <h2>Choose Your Plan</h2>
//         <div>
//             <button onClick={() => alert("Free Plan Selected")}>Free Plan</button>
//             <button onClick={() => alert("Premium Plan Selected")}>Premium Plan</button>
//         </div>
//         </div>
//     );
// }

export default SubscriptionPage;
