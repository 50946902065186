// TermsAndConditionsPage.js

import React from 'react';

function TermsAndConditionsPage() {
  return (
    <div>
      <h2>Terms And Conditions</h2>
    </div>
  );
}

export default TermsAndConditionsPage;
